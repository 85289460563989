import React, { useState, useRef, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import Logo from '../components/Logo/Logo';
import { StateProvider } from '../context/Context';
import Meta from '../components/Meta/Meta';
import Contact from '../components/Contact/Contact';
import About from '../components/About/About';
import Modal from '../components/Modal/Modal';
import Artists from '../components/Artists/Artists';
import Tours from '../components/Tours/Tours';
import { loadingAnimationTime } from '../consts/transitions';

export default function Home({ data }) {
  const {
    allSanityOrderArtists,
    allSanityOrderTours,
    sanityMeta: meta,
    sanityAbout: about,
  } = data;
  const artists = allSanityOrderArtists.nodes[0].ordered_artists;
  const tours = allSanityOrderTours.nodes[0].ordered_tours;

  // Setup for globally accessible section state
  const initialState = { openSection: 'Home' };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeSection':
        return {
          ...state,
          openSection: action.newOpenSection,
        };
      case 'changeIsMouseDown':
        return {
          ...state,
          isMouseDown: action.newIsMouseDown,
        };
      default:
        return state;
    }
  };

  // Modal state management
  const [isVisible, setIsVisible] = useState(false);
  const [modalData, setModalData] = useState(artists[0]);
  const [viewHeight, setViewheight] = useState(0);

  const disableScroll = () => {
    if (!document.body.classList.contains('noScroll'))
      document.body.classList.add('noScroll');
  };

  const enableScroll = () => {
    document.body.classList.remove('noScroll');
  };

  const openModal = (modalInfo) => {
    setIsVisible(true);
    setModalData(modalInfo);
    disableScroll();
  };

  const closeModal = () => {
    setIsVisible(false);
    enableScroll();
  };

  const viewWindowRef = useRef(null);
  const contentRef = useRef(null);
  const hideOnLoadRef = useRef(null);

  const calculateViewHeight = useCallback(() => {
    if (typeof window === 'undefined') return;
    setViewheight(window.innerHeight);
  }, []);

  useEffect(() => {
    calculateViewHeight();
    window.addEventListener('resize', calculateViewHeight);
    return () => window.removeEventListener('resize', calculateViewHeight);
  }, [calculateViewHeight]);

  // Remove the viewcrop after intro animation
  useEffect(() => {
    setTimeout(() => {
      contentRef.current.style.transition = 'height 1s, min-height 1s';
      contentRef.current.style.minHeight = `${viewHeight}px`;
      // This hides the content flashing on first page load, bit hacky but oh well
      hideOnLoadRef.current.removeAttribute('style');
    }, 1000);

    setTimeout(() => {
      viewWindowRef.current.removeAttribute('style');
    }, loadingAnimationTime);
  }, [viewHeight]);

  return (
    // This div is the view window which is cropped during the load animation
    <div
      style={{
        height: `${viewHeight}px`,
        overflow: 'hidden',
      }}
      ref={viewWindowRef}
    >
      <div
        style={{
          minHeight: `calc(${viewHeight}px + 4 * var(--tab-height)`,
          position: 'relative',
        }}
        className="contentContainer"
        ref={contentRef}
      >
        <StateProvider initialState={initialState} reducer={reducer}>
          <Meta title={meta.title} description={meta.description} />
          <Logo />
          <div style={{ opacity: 0 }} ref={hideOnLoadRef}>
            <div id="content">
              <About about={about} bgColor="var(--brown)" delay={1100} />
              <Artists
                bgColor="var(--grey)"
                artists={artists}
                openModal={openModal}
                delay={900}
              />
              <Tours
                bgColor="white"
                tours={tours}
                openModal={openModal}
                delay={700}
              />
              <Contact bgColor="black" textColor="white" delay={500} />
            </div>
            <Modal
              isVisible={isVisible}
              modalData={modalData}
              closeModal={closeModal}
              height={viewHeight}
            />
          </div>
        </StateProvider>
      </div>
    </div>
  );
}

export const query = graphql`
  query MyQuery {
    sanityAbout {
      about_blurb {
        text_input {
          children {
            text
          }
        }
      }
    }
    sanityMeta {
      description
      title
    }
    allSanityOrderArtists {
      nodes {
        ordered_artists {
          artist_blurb {
            text_input {
              children {
                text
              }
            }
          }
          artist_name
          subheading
          artist_logo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          artist_links {
            link_url
            link_text
          }
          image_alignment
          artist_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allSanityOrderTours {
      nodes {
        ordered_tours {
          tour_title
          tour_subtitle
          tour_links {
            link_url
            link_text
          }
          tour_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          tour_blurb {
            text_input {
              children {
                text
              }
            }
          }
          past
          dates {
            location
            date
          }
        }
      }
    }
  }
`;
