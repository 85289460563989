import React, { useState, useRef, useEffect, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useStateValue } from '../../context/Context';
import * as style from './Carousel.module.scss';
import ScrollTip from '../ScrollTip/ScrollTip';

// Prevent the plugin getting dropped by bundler
gsap.registerPlugin(ScrollToPlugin);

export default function Carousel({ children, section, color }) {
  const [{ openSection }] = useStateValue();
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const carouselRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [sliderMaxScroll, setSliderMaxScroll] = useState(0);

  useEffect(() => {
    setSliderMaxScroll(carouselRef.current.scrollWidth - carouselRef.current.clientWidth);
  });

  const dragMultiplier = 1.3;

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    carouselRef.current.scrollLeft += -e.movementX * dragMultiplier;
  };

  const animateCarousel = useCallback(() => {
    if (!hasBeenOpened) {
      setTimeout(() => setHasBeenOpened(true), 1200);
    }

    // const sliderMaxScroll = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;

    if (sliderMaxScroll > 0) {
      carouselRef.current.scrollTo(sliderMaxScroll > 500 ? 500 : sliderMaxScroll, 0);
      gsap.to(carouselRef.current, { duration: 0.8, scrollTo: { x: 0 } });
    }
  }, [sliderMaxScroll]);

  useEffect(() => {
    if (section === openSection) animateCarousel();
  }, [openSection, section, animateCarousel]);

  return (
    <div className={style.container}>
      {sliderMaxScroll > 0 && <ScrollTip color={color} visible={!hasBeenOpened} />}
      <div
        ref={carouselRef}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={handleMouseMove}
        onMouseUp={() => setIsMouseDown(false)}
        className={[style.carousel, isMouseDown ? 'active' : null].join(' ')}
        tabIndex={-1}
        role="menu"
      >
        {children}
      </div>
    </div>
  );
}
