import React, { useState } from 'react';
import Img from 'gatsby-image';
import * as style from './CarouselItem.module.scss';
import { useStateValue } from '../../context/Context';

const ArtistItem = ({ data, openModal }) => {
  const { artist_name: name, artist_image: image } = data;

  const [{ openSection }] = useStateValue();

  const [mouseDownPos, setMouseDownPos] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setMouseDownPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = (e) => {
    if (
      Math.abs(e.clientX - mouseDownPos.x) < 5 &&
      Math.abs(e.clientY - mouseDownPos.y) < 5
    )
      openModal(data);
  };

  return (
    <div className={style.container}>
      <button
        tabIndex={openSection === 'Artists' ? '0' : '-1'}
        type="button"
        className={style.content}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <Img fluid={image.asset.fluid} className={style.image} />
        <h3 className={[style.name, 'ffss'].join(' ')}>{name}</h3>
      </button>
    </div>
  );
};

const TourItem = ({ data, openModal }) => {
  const { tour_image: image, tour_title: title } = data;

  const [mouseDownPos, setMouseDownPos] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setMouseDownPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = (e) => {
    if (
      Math.abs(e.clientX - mouseDownPos.x) < 5 &&
      Math.abs(e.clientY - mouseDownPos.y) < 5
    )
      openModal(data);
  };

  const [{ openSection }] = useStateValue();
  return (
    <div className={style.container}>
      <button
        tabIndex={openSection === 'Tours' ? '0' : '-1'}
        type="button"
        className={style.content}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <Img fluid={image.asset.fluid} className={style.image} />
        <h3 className={[style.name, 'ffss'].join(' ')}>{title}</h3>
      </button>
    </div>
  );
};

export default function Artist({ data, type, openModal }) {
  return type === 'artist' ? (
    <ArtistItem data={data} openModal={openModal} />
  ) : (
    <TourItem data={data} openModal={openModal} />
  );
}
