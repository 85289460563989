import React, { useState, useEffect, useRef } from 'react';
import * as style from './Section.module.scss';
import Tab from '../Tab/Tab';
import { heightTransition } from '../../consts/transitions';
import { useStateValue } from '../../context/Context';

export default function Section({ section, bgColor, textColor, children, delay }) {
  const [{ openSection }, dispatch] = useStateValue();

  const [sectionHeight, setSectionHeight] = useState(0);
  const contentRef = useRef(null);

  const handleClick = () => {
    dispatch({
      type: 'changeSection',
      newOpenSection: section !== openSection ? `${section}` : 'Home',
    });
    dispatch({
      type: 'changeOpenSectionHeight',
      newOpenSectionHeight: sectionHeight,
    });
  };

  const calculateSectionHeight = () => {
    const padding = getComputedStyle(document.documentElement).getPropertyValue('--pad');
    const contentHeight = contentRef.current.getBoundingClientRect().height;
    setSectionHeight(`${padding} + ${contentHeight}px`);
  };

  // Setup height
  useEffect(() => {
    setTimeout(() => {
      calculateSectionHeight();
    }, 200);
    window.addEventListener('resize', calculateSectionHeight);
    return () => window.removeEventListener('resize', calculateSectionHeight);
  }, [sectionHeight]);

  const transformRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      transformRef.current.style.transform = 'translateY(0)';
    }, delay);
  }, [delay]);

  const getYTranslation = () => {
    switch (section) {
      case 'About':
        return 'translateY(400%)';
      case 'Artists':
        return 'translateY(300%)';
      case 'Tours':
        return 'translateY(200%)';
      case 'Get In Touch':
        return 'translateY(100%)';
      default:
        return 'translateY(100%)';
    }
  };

  return (
    <div ref={transformRef} style={{ transform: getYTranslation(), transition: 'all 1s' }}>
      <Tab text={section} bgColor={bgColor} textColor={textColor} handleClick={handleClick} />
      <div
        className={style.section}
        style={{
          background: bgColor,
          color: textColor,
          transition: heightTransition,
          height: openSection === section ? `calc(${sectionHeight})` : 0,
        }}
      >
        <div ref={contentRef} className={style.content}>
          {children}
        </div>
      </div>
    </div>
  );
}
