import React from 'react';
import * as style from './ScrollTip.module.scss';

export default function ScrollTip({ color, visible }) {
  return (
    <>
      <div className={style.background} style={{ background: color, opacity: visible ? 0.9 : 0 }} />
      <div className={[style.text, 'ffss'].join(' ')} style={{ opacity: visible ? 1 : 0 }}>
        Drag To Scroll
      </div>
    </>
  );
}
