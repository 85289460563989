import React from 'react';

import * as style from './Contact.module.scss';
import { useStateValue } from '../../context/Context';
import Section from '../Section/Section';

const ContactLine = ({ title, children }) => {
  return (
    <div className={[style.contactRow, 'ffss'].join(' ')}>
      <span className={style.rowTitle}>{title}. </span>
      <div className={style.rowFeature}>{children}</div>
    </div>
  );
};

export default function Contact({ bgColor, textColor, delay }) {
  const [{ openSection }] = useStateValue();
  const tabIndex = openSection === 'Get In Touch' ? '0' : '-1';

  return (
    <Section section="Get In Touch" bgColor={bgColor} textColor={textColor} delay={delay}>
      <ContactLine title="EM">
        <a className={style.underline} tabIndex={tabIndex} href="mailto:hello@tangleagency.com">
          hello@tangleagency.com
        </a>
      </ContactLine>
      <ContactLine title="IG">
        <a tabIndex={tabIndex} href="https://www.instagram.com/tangleagency/" target="_blank" rel="noreferrer noopener">
          @<span className={style.underline}>tangleagency</span>
        </a>
      </ContactLine>
      <ContactLine title="FB">
        <a tabIndex={tabIndex} href="https://www.facebook.com/tangleagency/" target="_blank" rel="noreferrer noopener">
          @<span className={style.underline}>tangleagency</span>
        </a>
      </ContactLine>
      <ContactLine title="Merch">
        <a
          className={style.underline}
          tabIndex={tabIndex}
          href="https://tangleagency.bandcamp.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bandcamp
        </a>
      </ContactLine>
      <p className={style.acknowledge}>
        We acknowledge the Traditional Owners of Country throughout Australia and recognise their continuing connection
        to land, waters and culture. We pay our respects to their Elders past, present and emerging.
      </p>
    </Section>
  );
}
