import React from 'react';
import Section from '../Section/Section';
import * as style from './About.module.scss';

export default function About({ about, bgColor, delay }) {
  return (
    <Section section="About" bgColor={bgColor} delay={delay}>
      <div className={style.aboutText}>
        {about.about_blurb.text_input.map((textBlock, i) => (
          <p key={`${i}aboutText`}>{textBlock.children[0].text}</p>
        ))}
      </div>
    </Section>
  );
}
