import React from 'react';
import { Helmet } from 'react-helmet';
import { useStateValue } from '../../context/Context';

export default function Meta({ description, title }) {
  const [{ openSection }] = useStateValue();

  const formattedTitle = () => (openSection !== 'Home' ? `${openSection} | ${title}` : `${title}`);

  return (
    <Helmet>
      <title>{formattedTitle()}</title>
      <meta name="description" content={description} />
      <link rel="icon" href="/images/favicon/tangle-fav.png" />
      <link rel="apple-touch-icon" href="/images/favicon/tangle-fav.png" />
      <link rel="mask-icon" href="/images/favicon/tangle-fav.png" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="Website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="images/socials/link-image.png" />
    </Helmet>
  );
}
