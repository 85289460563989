import React, { useEffect, useRef } from 'react';
import Img from 'gatsby-image';
import * as style from './Modal.module.scss';

export default function Modal({ modalData, isVisible, closeModal, height }) {
  const {
    artist_name: artistName,
    subheading: artistSubheading,
    artist_blurb: artistBlurb,
    artist_image: artistImage,
    image_alignment: imageAlignment,
    artist_links: artistLinks,
    artist_logo: artistLogo,
    dates: tourDates,
    tour_blurb: tourBlurb,
    tour_image: tourImage,
    tour_links: tourLinks,
    tour_subtitle: tourSubtitle,
    tour_title: tourTitle,
    past,
  } = modalData;

  const modalRef = useRef(null);

  useEffect(() => {
    modalRef.current.scrollTo(0, 0);
  }, [modalData]);

  return (
    <div
      className={[style.container, isVisible ? style.visible : null].join(' ')}
      style={{ height: `${height}px` }}
    >
      <div className={style.anchor}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events  */}
        <div
          className={style.background}
          onClick={closeModal}
          style={{ height: `${height}px` }}
        />
        <div className={style.content} style={{ maxHeight: `${height}px` }}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events  */}
          <div className={style.buttonContainer} onClick={closeModal}>
            <button
              className={[style.closeButton, 'ffss'].join(' ')}
              type="button"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
          <div className={style.scrollContainer}>
            <div
              className={style.card}
              ref={modalRef}
              style={{
                maxHeight: `calc(${height}px - (var(--pad) * 1) - 80px)`,
              }}
            >
              <div className={style.cardContent}>
                {/* Artist */}
                {artistName && (
                  <>
                    <div className={style.mobileTitles}>
                      <h3 className={['ffss', style.name].join(' ')}>
                        {artistName}
                      </h3>

                      {artistSubheading && (
                        <h4 className={['ffss', style.subtitle].join(' ')}>
                          {artistSubheading}
                        </h4>
                      )}
                    </div>
                    <div className={style.imageContainer}>
                      <Img
                        imgStyle={{ objectPosition: imageAlignment }}
                        fluid={artistImage.asset.fluid}
                        style={{ height: '100%' }}
                      />
                    </div>
                    <div className={style.textContent}>
                      <div className={style.textAndDates}>
                        <div className={style.desktopTitles}>
                          <h3 className={['ffss', style.name].join(' ')}>
                            {artistName}
                          </h3>
                          {artistSubheading && (
                            <h3 className={['ffss', style.subtitle].join(' ')}>
                              {artistSubheading}
                            </h3>
                          )}
                        </div>
                        <div className={style.modalTextContainer}>
                          {artistBlurb.text_input.map((textBlock, i) => (
                            <p
                              key={`${i}_artistText_${artistName}`}
                              className={style.modalText}
                            >
                              {textBlock.children[0].text}
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className={style.bottomBlock}>
                        <div className={style.links}>
                          {artistLinks.map((link, i) => (
                            <a
                              key={`${i}artistList${artistName}`}
                              target="_blank"
                              rel="norefferer noopener noreferrer"
                              href={link.link_url}
                              className="ffss"
                            >
                              {link.link_text}
                            </a>
                          ))}
                        </div>
                        <Img
                          fluid={artistLogo.asset.fluid}
                          className={style.logo}
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* Tour */}
                {tourTitle && (
                  <>
                    <div className={style.mobileTitles}>
                      <h3 className={['ffss', style.name].join(' ')}>
                        {tourTitle}
                      </h3>
                      <h4 className={['ffss', style.subtitle].join(' ')}>
                        {tourSubtitle}
                      </h4>
                    </div>
                    <div
                      className={[
                        style.imageContainer,
                        style.tourPosterContainer,
                      ].join(' ')}
                    >
                      <Img
                        fluid={tourImage.asset.fluid}
                        style={{ height: '100%' }}
                      />
                    </div>
                    <div className={style.textContent}>
                      <div className={style.textAndDates}>
                        <div className={style.desktopTitles}>
                          <h3 className={['ffss', style.name].join(' ')}>
                            {tourTitle}
                          </h3>
                          <h4 className={['ffss', style.subtitle].join(' ')}>
                            {tourSubtitle}
                          </h4>
                        </div>
                        <div className={style.modalTextContainer}>
                          {tourBlurb.text_input.map((textBlock, i) => (
                            <p
                              key={`${i}_tourBlurb_${tourTitle}`}
                              className={style.modalText}
                            >
                              {textBlock.children[0].text}
                            </p>
                          ))}
                        </div>
                        {tourDates.map((date, i) => (
                          <div
                            className={style.tourDate}
                            key={`tourDate${i}${tourTitle}`}
                          >
                            <div className={style.tourInfo}>
                              <p>{date.location}</p>
                              <p>{date.date}</p>
                            </div>
                            <div className={style.tourLine} />
                          </div>
                        ))}
                      </div>
                      <div className={style.bottomBlock}>
                        <div className={style.links}>
                          {tourLinks.map((link, i) => (
                            <a
                              key={`${i}tourLink${tourTitle}`}
                              target="_blank"
                              rel="norefferer noopener noreferrer"
                              href={link.link_url}
                              className={['ffss', style.tourLink].join(' ')}
                            >
                              {link.link_text}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
