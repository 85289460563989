import React from 'react';
import * as style from './Tab.module.scss';

export default function Tab({ bgColor, text, textColor = 'black', handleClick }) {
  return (
    <div className={style.container} style={{ background: bgColor, color: textColor }}>
      <button
        onClick={handleClick}
        type="button"
        className={style.button}
        style={{ background: bgColor, color: textColor }}
      >
        <span className="ffss">{text}</span>
      </button>
    </div>
  );
}
