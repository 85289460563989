import React from 'react';
import * as style from './ToursPartition.module.scss';

export default function ToursPartition({ text }) {
  return (
    <div className={[style.container, text === 'Past' || text === 'Worldwide' ? style.past : null].join(' ')}>
      <div className={style.content}>
        <div className={style.hLine} />
        <div className={style.vLine} />
        <p className={['ffss', style.text].join(' ')}>{text}</p>
        <div className={style.vLine} />
        <div className={style.hLine} />
      </div>
    </div>
  );
}
