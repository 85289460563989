import React from 'react';
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../CarouselItem/CarouselItem';
import Section from '../Section/Section';
import ToursPartition from '../ToursPartition/ToursPartition';

export default function Tours({ bgColor, tours, openModal, delay }) {
  const futureTours = [];
  const pastTours = [];
  for (let i = 0; i < tours.length; i += 1) {
    if (tours[i].past) pastTours.push(tours[i]);
    else futureTours.push(tours[i]);
  }

  return (
    <Section section="Tours" bgColor={bgColor} delay={delay}>
      <Carousel section="Tours" color={bgColor}>
        <ToursPartition text="Upcoming" />
        {futureTours.map((tour, i) => (
          <CarouselItem key={`${i}tour`} data={tour} openModal={openModal} />
        ))}
        <ToursPartition text="Past" />
        {pastTours.map((tour, i) => (
          <CarouselItem key={`${i}tour`} data={tour} openModal={openModal} />
        ))}
      </Carousel>
    </Section>
  );
}
