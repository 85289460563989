import React from 'react';
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../CarouselItem/CarouselItem';
import Section from '../Section/Section';

export default function Artists({ artists, bgColor, openModal, delay }) {
  return (
    <Section section="Artists" bgColor={bgColor} delay={delay}>
      <Carousel section="Artists" color={bgColor}>
        {artists.map((artist, i) => (
          <CarouselItem
            key={`${i}artist`}
            type="artist"
            data={artist}
            openModal={openModal}
          />
        ))}
      </Carousel>
    </Section>
  );
}
